import React from 'react';

const BOnnetworkWidgetEditor = props => {

    return (
        <div>
            {props.children}
            <div className="form-group">
                <label className="col-sm-3 control-label">Onnetwork Widget ID</label>
                <div className="col-sm-9">
                    <input type="text" name="onnetwork_widget_id" className="form-control" defaultValue={props.settings.onnetwork_widget_id} />
                </div>
            </div>
        </div>
    );
}


export default BOnnetworkWidgetEditor;