import React from "react";

import Editor from "./BOnnetworkWidgetEditor";
import EditorWrapper from '../../EditorWrapper';

import OnnetworkWidget from "../../../redesign/components/OnnetworkWidget";

const BOnnetWorkWidget = props => {

    const { settings } = props;
    const onnetwork_widget_id = settings.onnetwork_widget_id;

    return (
        <div className={props.classes + ' pos-relative'} {...props.events} >
            <EditorWrapper {...props} editor={Editor} />
            <OnnetworkWidget
                onnetwork_widget_id={onnetwork_widget_id}
                {...props}
            />
        </div>
    )
}

export default BOnnetWorkWidget;