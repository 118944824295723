import React, { useEffect, useRef } from "react";
import { styled } from "../../stitches.config";

interface OnnetworkWidgetProps {
  onnetwork_widget_id: number;
}

const OnnetworkWidget: React.FC<OnnetworkWidgetProps> = ({
  onnetwork_widget_id,
  ...props
}) => {

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.setAttribute("src", `https://video.onnetwork.tv/widget/widget_playlist.php?widget=${onnetwork_widget_id}`);

    ref.current?.appendChild(s);
  }, [])

  return (
    <StyledOnnetworkWidget>
      <OnnetworkContainer
        ref={ref}
      />
    </StyledOnnetworkWidget>
  );
};

const StyledOnnetworkWidget = styled("div", {
  width: "100%",
  padding: "24px 12px",
  background: "$grayscale100Fixed",
  minHeight: "500px",
  marginBottom: "24px",
  "@bp4": {
    padding: "48px 24px",
    minHeight: "300px",
  },
  "@bp5": {
    minHeight: "460px",
  }
});

const OnnetworkContainer = styled("div", {
  maxWidth: "100%",
  height: "auto",
  position: "relative",

  // widok mobile sprawdzać tylko na real device, nie w przeglądarce

  "& .widgetcontainerexs": {
    height: "300px",
    "@bp3": {
      height: 'auto',
    }
  }

});


export default OnnetworkWidget;
